import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../../middleware/firebase";
import { BannerType } from "../../../models/banners_model";

const MiniBanner: React.FC = () => {
  const page =
    process.env.REACT_APP_IS_PROD === "false"
      ? "mathasa.mx"
      : window.location.host;
  const [banner, setBanner] = useState<BannerType | false>(false);
  useEffect(() => {
    fetchBanners();
  }, []);

  const fetchBanners = async () => {
    const bannersRef = db.collection("banners");
    const location = JSON.parse(localStorage.getItem("location") || "{}");
    const set_banners: BannerType[] = [];
    const start = new Date();
    const snapshot = await bannersRef
      .where("valid", ">=", start)
      .where("websites", "array-contains", page)
      .get();
    if (snapshot.empty) {
      setBanner(false);
      console.log("No matching documents.");
      return;
    }

    snapshot.forEach((doc) => {
      const banner_data = doc.data() as BannerType;
      banner_data.id = doc.id;
      let add_it = true;
      if (banner_data.zones) {
        const finded = banner_data.zones.findIndex(
          (zone) => zone === location.id
        );
        if (finded < 0 || !banner_data.mini_img) {
          add_it = false;
        }
      }
      if (add_it) {
        set_banners.push(banner_data);
      }
    });

    const random_banner =
      set_banners[Math.floor(Math.random() * set_banners.length)];
    setBanner(random_banner);
  };
  if (!banner) {
    return null;
  }
  return (
    <div className="mini_banner">
      {banner.link ? (
        <Link to={banner.link}>
          <img src={banner.mini_img} alt={banner.name} className="img-fluid" />
        </Link>
      ) : (
        <img src={banner.mini_img} alt={banner.name} className="img-fluid" />
      )}
    </div>
  );
};

export default MiniBanner;
