import React from 'react';

import atrato_logo from '../../../assets/atrato.png';
import rappi_pay_img from '../../../assets/rappi_pay.png';
import coppelpay_logo from '../../../assets/coppelpay_logo.png';
import { RichButton } from '../../common/buttons';
//@ts-ignore next line
import { usePaymentInputs } from 'react-payment-inputs';
import { CardIcon } from '../../common/cards.js';
import Loader from '../../common/loader';
import { PaymentAddress, AddressComponent } from './addressComponent';
import ShippingCosts from './shippingCost';
import {
  CardData,
  DeliveryTypes,
  MesesItem,
  PaymentTypes,
} from '../../../models/checkout_model';
import { UserDeliveryData } from '../../../models/user_model';
import { CartProductItem, ShppingResponse } from '../../../models/cart_model';

type PaymentSectionProps = {
  selected_card?: any;
  selectPayemntType: any;
  payment_type: PaymentTypes | false;
  clickFun: any;
  handleCartData: any;
  promtOpenPey: any;
  userCards: CardData[] | false;
  loading: boolean;
  selectPrevCard: any;
  handlePaymentAddress: any;
  paymentAddress: UserDeliveryData;
  handlePaymentAddressSelection: any;
  delivery_type: DeliveryTypes;
  error_message: null | string;
  cardData: CardData;
  savingcard: any;
  meses: MesesItem[];
  products: CartProductItem[];
  removeFun: any;
  shippingcost: ShppingResponse;
  changePriceAlert: any;
  zip: string;
  total: number;
};
type CardPaymentProps = {
  getCardNumberProps: any;
  getExpiryDateProps: any;
  getCVCProps: any;
  handleCartData: any;
  cardData: CardData;
  cardType: any;
};

const PaymentSection: React.FC<PaymentSectionProps> = ({
  selectPayemntType,
  payment_type,
  clickFun,
  handleCartData,
  promtOpenPey,
  userCards,
  loading,
  selected_card,
  selectPrevCard,
  handlePaymentAddress,
  paymentAddress,
  handlePaymentAddressSelection,
  error_message,
  cardData,
  savingcard,
  meses,
  products,
  removeFun,
  shippingcost,
  delivery_type,
  changePriceAlert,
  zip,
  total,
}) => {
  const { getCardNumberProps, getExpiryDateProps, getCVCProps, meta } =
    usePaymentInputs();
  const meses_options = meses.map((item) => item.meses);
  const mesesPayment = meses_options
    ? `Paga en plazos de 1, ${meses_options.join(' o ')} meses`
    : '';

  const Prevcards = () => (
    <div className="prev_cards my-3">
      <h5>Tarjetas Guardadas</h5>
      <div className="row">
        <div className="col-md-8">
          {userCards &&
            userCards.map((card, key) => {
              let icon;
              switch (card.brand) {
                case 'american_express':
                  icon = 'amex';
                  break;
                default:
                  icon = card.brand ? card.brand.toLowerCase() : '';
                  break;
              }
              return (
                <CardIcon
                  key={key}
                  icon={`fab fa-cc-${icon}`}
                  title={card.name}
                  desc={`Terminación: ${card.number}`}
                  cta="Seleccionar"
                  clickFun={selectPrevCard}
                  dataAttr={card.id}
                  selected={selected_card === card.id}
                  size="sm"
                />
              );
            })}
        </div>
      </div>
      <h5>O Agrega una Nueva Tarjeta</h5>
    </div>
  );

  if (loading || !shippingcost.shippingCosts) {
    return (
      <div className="col-md-6">
        <Loader
          half
          txt={
            !shippingcost.shippingCosts
              ? delivery_type === 'shipping'
                ? 'Calculando costos de envío...'
                : 'Consultando inventario...'
              : 'Cargando'
          }
        />
      </div>
    );
  }
  return (
    <div className="payment_section col-md-8">
      <ShippingCosts
        removeFun={removeFun}
        products={products}
        changePriceAlert={changePriceAlert}
        shippingcost={shippingcost}
        delivery_type={delivery_type}
        zip={zip}
      />
      <h4>Selecciona tu método de pago:</h4>
      <div className="btn_rich_container mob_full">
        <RichButton
          title="Pago Seguro Online"
          classes={'online_btn'}
          small={true}
          icon="fa-fw far fa-credit-card"
          value="online"
          active={payment_type === 'online'}
          clickFun={selectPayemntType}
        />
        <RichButton
          title="Transferencia Bancaria"
          classes="transfer_btn"
          small={true}
          icon="fa-fw fas fa-university"
          value="transfer"
          active={payment_type === 'transfer'}
          clickFun={selectPayemntType}
        />
        <RichButton
          classes="paypal_btn"
          icon="fa-fw fab fa-cc-paypal "
          title="Paga con PayPal"
          value="paypal"
          active={payment_type === 'paypal'}
          clickFun={selectPayemntType}
        />
        <RichButton
          classes={' atrato_btn ' + (total < 1000 ? 'disabled' : '')}
          title="Paga a tu propio ritmo"
          small={true}
          value="atrato"
          active={payment_type === 'atrato'}
          clickFun={selectPayemntType}
          disabled={total < 1000}
        >
          <img src={atrato_logo} alt="Paga con Atrato" />
          {total < 1000 ? (
            <div className="small">Solo en compras mayores de $1,000 MXN</div>
          ) : null}
        </RichButton>
        <RichButton
          title="Usa tu crédito Coppel"
          classes={'coppel_btn' + (total > 15000 ? 'disabled' : '')}
          small={true}
          icon=""
          value="coppel"
          active={payment_type === 'coppel'}
          clickFun={selectPayemntType}
          disabled={total > 15000}
        >
          <img src={coppelpay_logo} alt="Paga con Rappi" />
          {total > 15000 ? (
            <div className="small">Solo compras de hasta $15,000 MXN</div>
          ) : null}
        </RichButton>
      </div>

      <p className="text-center mt-3">
        <small className="d-block text-muted mt-1">
          {' '}
          <i
            className="fa fa-check-circle text-success"
            aria-hidden="true"
          ></i>{' '}
          Tus pagos se realizan de forma segura con encriptación de 256 bits{' '}
        </small>
      </p>
      {payment_type === 'online' && userCards && userCards.length > 0 ? (
        <Prevcards />
      ) : null}
      {payment_type === 'online' && (
        <CardPayment
          getCardNumberProps={getCardNumberProps}
          getExpiryDateProps={getExpiryDateProps}
          getCVCProps={getCVCProps}
          handleCartData={handleCartData}
          cardData={cardData}
          cardType={meta.cardType}
        />
      )}
      {payment_type === 'online' && delivery_type === 'shipping' ? (
        <PaymentAddress
          paymentAddress={paymentAddress ? true : false}
          handlePaymentAddressSelection={handlePaymentAddressSelection}
        />
      ) : null}
      {payment_type === 'online' &&
      (paymentAddress || delivery_type !== 'shipping') ? (
        <React.Fragment>
          <h5>Dirección de la Tarjeta</h5>
          <AddressComponent
            handleDelivery={handlePaymentAddress}
            deliveryData={paymentAddress}
            is_card
          />
        </React.Fragment>
      ) : null}
      {payment_type === 'transfer' && <TransferPayment />}

      {payment_type && (
        <React.Fragment>
          {error_message && (
            <div className="alert alert-danger" role="alert">
              <p>
                <i className="fas fa-exclamation-triangle"></i> {error_message}
              </p>
            </div>
          )}
          <button
            className="btn btn-dark text-capitalize py-4 py-md-3 btn-payment fixed"
            data-next="resume"
            disabled={savingcard ? true : false}
            onClick={
              !payment_type || payment_type === 'online'
                ? promtOpenPey
                : clickFun
            }
          >
            {savingcard ? 'Validando Tarjeta...' : 'Continuar'}
          </button>
          <p className="text-center">
            <small className="d-block text-muted mt-1">
              {' '}
              <i
                className="fa fa-check-circle text-success"
                aria-hidden="true"
              ></i>{' '}
              Tus pagos se realizan de forma segura con encriptación de 256 bits{' '}
            </small>
          </p>
          <small className="text-help mt-2 d-block text-muted">
            Necesitas ayuda con tu pedido o tienes dudas? <br />
            Estámos para atenderte en el correo <b>servicio@mathasa.com.mx</b> o
            al teléfono <b>614-442-9000</b>
          </small>
        </React.Fragment>
      )}
    </div>
  );
};

const TransferPayment = () => (
  <div className="transfer_data my-5">
    <p>Datos de Pago</p>
    <table className="table">
      <thead>
        <tr>
          <th>Banco</th>
          <th>CLABE</th>
          <th>Nombre</th>
          <th>Referencia</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Banregio</td>
          <td>058 150 6004 1980 0122</td>
          <td>Mathasa S.A. de C.V.</td>
          <td className="text-muted">Se te enviará por correo</td>
        </tr>
      </tbody>
    </table>
  </div>
);

const CardPayment: React.FC<CardPaymentProps> = ({
  getCardNumberProps,
  getExpiryDateProps,
  getCVCProps,
  handleCartData,
  cardData,
  cardType,
}) => (
  <div className="row my-5 ">
    <div className="col-md-8">
      <div className="form-group">
        <label htmlFor="inputPassword4">Número de Tarjeta</label>
        <input
          {...getCardNumberProps({
            onChange: handleCartData,
          })}
          data-type="number"
          type="phone"
          className="form-control"
          placeholder="XXXX XXXX XXXX XXXX"
          value={cardData.number}
        />
      </div>
      <div className="form-group">
        <label htmlFor="inputEmail4">Nombre en la Tarjeta</label>
        <input
          onChange={handleCartData}
          data-type="name"
          type="text"
          className="form-control"
          value={cardData.name}
        />
      </div>
      <div className="form-row">
        <div className="form-group col-md-8">
          <label htmlFor="inputEmail4">Exp</label>
          <input
            {...getExpiryDateProps({
              onChange: handleCartData,
            })}
            data-type="date"
            type="text"
            className="form-control"
            placeholder="MM/AA"
          />
        </div>
        <div className="form-group col-md-4">
          <label htmlFor="inputPassword4">CVV</label>
          <input
            {...getCVCProps({
              onChange: handleCartData,
            })}
            data-type="cvv"
            type="text"
            className="form-control"
            value={cardData.cvv}
          />
          <input
            type="hidden"
            value={cardType ? cardType.displayName : 'false'}
            id="metaCardData"
          />
        </div>
      </div>
    </div>
  </div>
);

export default PaymentSection;
